<template>
  <div>
    <v-row class="align-center">
      <!-- Left Half: Image -->
      <v-col cols="12" sm="4" class="d-flex">
        <img
          src="@/assets/thingsToDo/riverdone.jpg"
          alt="SouthWest Middle School"
          class="school-logo"
          style="height: auto; width: 50%"
        />
      </v-col>

      <!-- Right Half: School Name -->
      <v-col cols="12" sm="6" class="d-flex left">
        <h1 class="school-name">Riverhead Central School District</h1>
      </v-col>
    </v-row>
    <!-- Divider Line (Optional, just to separate sections visually) -->
    <v-divider class="my-4"></v-divider>

    <!-- Content Section: "What are we raising money for?" -->
    <v-row>
      <v-col cols="12" style="margin-left: -5px">
        <div class="heading">
          <div class="question-title">What are we raising money for?</div>
          <div class="hLine"></div>
        </div>

        <!-- Paragraph Section -->
        <div class="paragraphMessage">
          <p>
            We are excited to announce that the students of Riverhead Central
            School District are teaming up with the World Famous Harlem Wizards
            to help raise funds for the elementary schools with their new WizFit
            Challenge fundraiser and kids experience. During a two-week period
            starting on <strong>10-07-2024</strong>, our students will watch and
            participate in an exclusive series of six interactive video episodes
            led by Harlem Wizard stars Swoop and Broadway. They will get fit,
            learn tricks, have fun, earn prizes, and be coached on character by
            the Wizards.
          </p>
          <p>
            We will then conclude this event on <strong>10-21-2024</strong> with
            a Graduation Celebration where every student will participate and
            win free prizes.
          </p>
          <p>
            On <strong>11-07-2024</strong> at Riverhead High School, the party
            continues with the Harlem Wizards Vs the Teachers. The game begins
            at 7 pm, and the doors will open at 6 pm. Be sure to purchase your
            tickets for the game in advance.
          </p>
          <a
            href="https://www.harlemwizards.com/tickets/riverhead"
            target="_blank"
            style="text-decoration: none"
          >
            <h3>HARLEM WIZARDS BOX OFFICE</h3>
          </a>
          <p>
            All sponsorships received will go towards the elementary experience.
            Our goal is to raise $10,000. Students can request donations from
            friends and family to support their participation in the WizFit
            Challenge. We're excited to see our school come together for this
            fundraising program.
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CampaignMessageDistrict",
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.school-name {
  font-size: 35px; /* Default for web */
  font-weight: bold;
  color: #2c1963;
  margin: 0;
  padding-left: -25px;
  margin-left: 26px;
}

@media (max-width: 600px) {
  /* Target mobile devices */
  .school-name {
    font-size: 18px; /* Adjusted for mobile */
    padding-left: -12px;
    margin-top: -21%;
    margin-left: 110px;
  }
}

.heading {
  font-size: 1.8rem;
  font-weight: bold;
  color: #3f51b5;
  margin-top: 20px;
  margin-left: 5%;
  display: flex;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}

.paragraphMessage {
  font-size: 18px;
  margin-top: 20px;
  line-height: 1.6;
  margin-left: 5%;
}

@media (max-width: 600px) {
  /* Target mobile devices */
  .paragraphMessage {
    font-size: 12px;
    margin-top: 20px;
    line-height: 1.6;
    margin-left: 5%;
  }
}

.question-title {
  color: #2c1963;
  font-weight: bold;
}
.school-logo {
  width: 100%;
  max-width: 250px; /* Default for web */
  display: block;
  margin-left: 15%;
}

@media (max-width: 600px) {
  /* Target mobile devices */
  .school-logo {
    max-width: 90px; /* Adjusted for mobile */
    margin-left: 1%;
    margin-top: 19px;
  }
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .hLine {
    background-color: #2c1963;
    width: 30px;
    height: 3px;
    float: right;
  }
}
</style>
